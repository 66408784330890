import React, { Component } from 'react'
import {  withRouter } from 'react-router-dom'

import Wrangler from '../imgs/car_img_main/4.jpg'

import '../css/home.css'
import axios from 'axios'
import utils from './utils'

import BttButton from './bttButton'
import CarPreviewCard from './carPreviewCard'
import InfiniteScroll from 'react-infinite-scroll-component'

class Home extends Component {
  
  state = {
    banner_imgs: [],
    vehicleList: [],
    vehicleFetchPath: 'vehicle/?page=1&page_size=10',
    hasMoreVehicle: false,
    sortingText: '排序',
  }

  componentWillMount = async () => {
    document.title = "95租车 - 洛杉矶最大的华人中高端租车平台";

    // bug fixing for duplicate vehicle loading after back from other pages
    this.setState({hasMoreVehicle: false})

    // load home page picture
    axios.get(utils.getUrl('exotics/vehicle-image/?filter={"tag": "Home Page"}'))
      .then(response => {
        console.log(response.data)
        this.setState({banner_imgs: response.data})
      })


    // load vehicle data
    this.fetchMoreVehicle()
  }

  fetchMoreVehicle = async() => {
    axios.get(utils.getUrl('exotics/'+this.state.vehicleFetchPath))
      .then(response => {
        this.setState({ 
          vehicleList: this.state.vehicleList.concat(response.data.results),
          hasMoreVehicle: response.data.next ? true : false,
          vehicleFetchPath: response.data.next ? `vehicle/?${response.data.next.split("?")[1]}` : ''
        })
      })
  }

  updateSorting = (key, value, sortingText) => {
    this.setState({
      vehicleFetchPath: `vehicle/?page=1&page_size=10&target={"${key}":${value}}`,
      vehicleList: [],
      hasMoreVehicle: false,
      sortingText,
    }, () => {
      this.fetchMoreVehicle()
    })
  }

  render() {

    return (
      <div style={{ overflowX: 'hidden'}} >
        <div className='container-fluid starter-container'>
          
          <div id="carouselExampleControls" class="carousel slide carousel-fade" data-bs-ride="carousel">
            <div class="carousel-inner d-flex align-items-center" style={{ maxHeight: 500, overflow: "hidden" }}>
              <div className="carousel-item active">
                <img src={Wrangler} class="starter-img" alt="home page main" />
              </div>
              {this.state.banner_imgs.map((img, index) => {
                return (
                  <div className="carousel-item">
                    <img src={img.image} class="starter-img" alt="home page main" />
                  </div>
                )
              })}
              <div className='starter-text-btn-block'>
                <div className='starter-text-main'>95 租车</div>
                <div className='starter-text-sub'>专业做品质，真诚做服务</div>
                <div className='starter-text-sub'>值得信赖的专业租车平台</div>
                <a href="https://findself.va.mikecrm.com/w2L8vIO" target="_blank" rel="noreferrer"><button className='starter-btn btn'>开始租车</button></a>
              </div>
            </div>
          </div>
          
        </div>
        <div className="container">
          <div className="row fs-2 fw-bold mt-5 mb-3 ms-1 d-flex justify-content-between">
            <div className='col-12 col-md-5 ps-0'>热门车辆</div>
            <div className='col-12 col-md-5 d-flex justify-content-end'>
              <div class="dropdown">
                <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{color: 'black', borderColor: 'black'}}>
                  {this.state.sortingText}
                </button>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                  <li><div class="dropdown-item" onClick={() => this.updateSorting('price', 0, '价格从低到高')}>价格从低到高</div></li>
                  <li><div class="dropdown-item" onClick={() => this.updateSorting('price', 9999, '价格从高到低')}>价格从高到低</div></li>
                </ul>
              </div>
            </div>
          </div>
          
          <InfiniteScroll
            dataLength={this.state.vehicleList.length}
            next={this.fetchMoreVehicle}
            hasMore={this.state.hasMoreVehicle}
            loader={
              <div className='d-flex justify-content-center my-5'>
                <div class="spinner-border text-warning" style={{ width: 20, height: 20, borderWidth: 3 }} role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            }
            endMessage={
              <p style={{ textAlign: "center", color: '#808080' }}>
                <b> - 95 Car Rental - </b>
              </p>
            }
            className="row d-flex flex-wrap g-5 mb-5">

              {this.state.vehicleList.map(vehicle => {
                return (<CarPreviewCard {...vehicle} />)
              })}

          </InfiniteScroll>
          
          
            
            
        </div>
        <BttButton></BttButton>
      </div>
    )
  }
}

export default withRouter(Home);