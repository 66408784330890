import { red } from "@material-ui/core/colors";
import { Component } from "react";

import MapPin from '../imgs/car_icons/MapPin@2x.png'
import Medal from '../imgs/car_icons/Medal@2x.png'

import { withRouter } from 'react-router-dom'

class CarPreviewCard extends Component {

    state = {
        image: this.props.image.filter(img => img.tag.includes("Vehicle Main"))
    }

    directCarDetailPage = (vehicle_id) => {
        this.props.history.push({
            pathname: `/car`,
            search: `?id=${vehicle_id}&make=${this.props.make}&model=${this.props.model}&year=${this.props.year}`,
        })
    }

    render() {
        return (
            <div className="col-lg-6 col-md-6 py-0 py-md-3">
                <div className="card h-100" onClick={() => this.directCarDetailPage(this.props.id)}>
                    <div class="ratio">
                        <img src={this.state.image.length > 0 ? this.state.image[0].compress40 : ''} class="card-img-top" alt="暂无图片" />
                    </div>
                    <div className="card-body d-flex justify-content-between align-items-center">

                        <div style={{ fontSize: 22, fontWeight: 400 }}>{this.props.make} {this.props.model} {this.props.year}</div>
                        <div className='d-flex flex-column'>
                            <div style={{ fontSize: 14, fontWeight: 600 }}>${this.props.price}/天</div>
                            <div style={{ fontSize: 12, fontWeight: 300, textDecoration: 'line-through', whiteSpace: "nowrap", color: 'red' }}>原价${Math.round(this.props.price * 1.2)}/天</div>
                        </div>
                        {/* <div>
                            <div className="d-flex flex-row mt-1" style={{ fontSize: 14, fontWeight: 300 }}>
                                <div className="me-3">
                                    <img src={MapPin} style={{ height: 20, width: 20 }} alt="MapPin" />
                                    {this.props.location}
                                </div>
                                <div>
                                    <img src={Medal} style={{ height: 20, width: 20 }} alt="Medal" />
                                    4.9
                                </div>
                            </div>
                            <hr style={{ borderTop: '1px solid #bbb', margin: '10px -20px 8px' }}></hr>
                        </div> */}
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CarPreviewCard);