import axios from "axios";
import { Component, React } from "react";
import utils from "./utils";

export default class Admin extends Component {

    state = {
        tableSchemas: {
            vehicle: ['ID', 'MAKE', 'MODEL', 'YEAR', 'PRICE', 'LOCATION', 'OWNER', 'CONFIG'],
            owner: ['ID', 'NAME', 'TRIPS', 'JOINED', 'RATING', 'PHONE', 'EMAIL'],
            config: ['ID', 'DISPLAY', 'DETAIL', 'TYPE'],
            image: ['ID', 'VEHICLE_ID', 'URL']
        },
        selectedTable: 'vehicle',
        selectedSchema: ['ID', 'MAKE', 'MODEL', 'YEAR', 'PRICE', 'LOCATION', 'OWNER', 'CONFIG'],
        tableData: []
    }

    componentWillMount = async () => {
        document.title = "X Exotics | Admin";

        /*
        await axios.get(utils.getUrl('vehicle/'))
        .then(response => {
            console.log(response.data)
            this.setState({tableData: response.data})
        })
        */
    }

    render() {
        return (
            <div>
                <div class="btn-group" role="group" className="m-2">
                    如需写入或更新数据，请先点击此处<a href="https://api.xexotics.autos/admin" target="_blank" rel="noreferrer">登录</a>后返回操作。
                    <a href={utils.getUrl('vehicle/')} target="_blank" rel="noreferrer"><button type="button" class="btn btn-outline-dark me-2">车辆</button></a>
                    <a href={utils.getUrl('owner/')} target="_blank" rel="noreferrer"><button type="button" class="btn btn-outline-dark me-2">车主</button></a>
                    <a href={utils.getUrl('config/')} target="_blank" rel="noreferrer"><button type="button" class="btn btn-outline-dark me-2">配置</button></a>
                    <a href={utils.getUrl('vehicle-image/')} target="_blank" rel="noreferrer"><button type="button" class="btn btn-outline-dark me-2">图片</button></a>
                </div>
                {/*
                <div className="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                {this.state.selectedSchema.map(attribute => {
                                    return (<th scope="col">{attribute}</th>)
                                })}
                                <th scope="col">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.tableData.map(data => {
                                var row = []
                                for (var key in data) {
                                    row.push(<td>{data[key]}</td>)
                                }
                                return (<tr>{row}</tr>)
                            })}
                        </tbody>
                    </table>
                </div>
                */}
            </div>
        )
    }

}