import { Component } from "react";
import { withRouter } from "react-router";
import utils from './utils'
import axios from 'axios'

class Login extends Component {

    state = {
        email: '',
        password: ''
    }

    handleEmail = (event) => {
        this.setState({ email: event.target.value })
    }

    handlePassword = (event) => {
        this.setState({ password: event.target.value })
    }

    handleSubmit = async(event) => {
        event.preventDefault()
        //get login token
        await axios.post(utils.getAuthUrl('token-auth/'),
            { username: this.state.email, password: this.state.password })
            .then(response => {
                localStorage.setItem('loginToken', response.data.token)
                this.props.history.push({ pathname: '/home' })
            }).catch(err => {
                if (err.response.status === 400) {
                    alert('用户名或密码错误！')
                }
            })
    }

    render() {
        return (
            <div>
                <input type="email" id="email" placeholder="邮箱" value={this.state.email} onChange={this.handleEmail} required />
                <input type="password" id="password" placeholder="密码" value={this.state.password} onChange={this.handlePassword} required />
                <button id="submit" type="submit" className="btn starter-btn" onClick={this.handleSubmit}>登录</button>
            </div>
        )
    }
}

export default withRouter(Login)