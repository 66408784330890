import React, { Component } from 'react'
import {Switch, Route, Redirect } from "react-router-dom"
import TopNavBar from './top-nav-bar'
import Footer from './footer'
import Home from './home'
import CarDetail from './carDetail'
import Login from "./login";
import AdminPage from './admin'
import NotFoundPage from './notFoundPage'


import Cookie from './cookie'
import Order from './order'
import aboutUs from './aboutUs'




export default class App extends Component {
  constructor(props) {
    super(props);
    this.nav = React.createRef();
  }

  state={
    modalText:"",
    showModal: false,
    hasGap: true,
    displayedURL: '/home',
    callback:()=>{}
  }

  componentDidMount() {
  }
  
  render() {
    return (
      <div>
        <TopNavBar ref={this.nav}></TopNavBar>
        {/* content section */}
        <Switch>
          <Route exact path='/home' render={()=> <Home cancelGap={this.cancelGap} addGap={this.addGap} setDisplayURL={this.setDisplayURL}></Home>}></Route>
          <Route exact path='/car' component={CarDetail}></Route>
          <Route exact path='/login' component={Login}></Route>
          <Route path='/order' component={Order}></Route>
          <Route exact path='/admin' component={AdminPage}></Route>
          <Route exact path='/aboutus' component={aboutUs}></Route>

          <Redirect exact path='/' to='/home/'></Redirect>
          <Route path='*' exact component={NotFoundPage} />
        </Switch>
        <Footer></Footer>
        <Cookie/>
      </div>
    )
  }
}
