import { Component } from "react";
import bttButton from '../imgs/back-to-top-button.png'

export default class BttButton extends Component {

    state = {
        showBttButton: false
    }

    componentDidMount = () => {
        document.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                this.setState({ showBttButton: true })
            } else {
                this.setState({ showBttButton: false })
            }
        })
    }

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    render() {
        return (
            <div>
                {this.state.showBttButton &&
                    <img src={bttButton} alt="back to top"
                        className='btt-btn' onClick={() => this.scrollToTop()} style={{
                        height: 50,
                        width: 50,
                        position: 'fixed',
                        bottom: '6%',
                        right: '2%',
                        zIndex: 99,
                        cursor: 'pointer'
                    }} />}
            </div>       
        )
    }

}