import React, { Component } from 'react'
import mainSlide from '../assets/images/main-slide-img1.png'


export default class NotFoundPage extends Component {
  render() {
    return (
      <div id="top-content" class="container-fluid">
      <div class="container">
          <div class="row">
              <div class="col-md-12 text-center">
                  <div id="main-slider">
                      <div class="slide info-slide1" title="Welcome !">
                          <div class="image-holder"><img src={mainSlide} alt="main-slide-img1" /></div>
                          <div class="text-holder txt animated">404<br/>
                            Page Not Found!
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    )
  }
}
