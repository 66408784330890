import caricon from '../imgs/caricon.png'
import utils from './utils'

import React, { Component } from 'react'

import '../css/top-nav-bar.css'

import WebsiteLogo from "../imgs/website_logo.png";
import { NavLink } from 'react-router-dom'

export default class TopNavBar extends Component {
  handleLogout = () => {
    utils.logout();
  }

  componentDidMount = () => {
    document.querySelectorAll('.navbar-nav-link').forEach(item => {
      item.addEventListener('click', event => {
        if (window.innerWidth < 992) {
          document.querySelector(".navbar-toggler").click();
        }
      })
    })
  }

  render() {

    return (
      <div>
        <nav className="container-fluid navbar navbar-expand-lg px-lg-10">
          <a className="navbar-brand" href="/#/home">
            <img src={WebsiteLogo} style={{ height: 60 }} alt="95Carrental" />
          </a>
          {/* <div className='login-register-block'>
            {'登录 ｜ 注册'}
          </div> */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <h2 className="navbar-toggler-icon brand-primary-color"><i className="bi bi-filter-circle"></i></h2>
          </button>
          <div className="collapse navbar-collapse ml-auto" id="navbarSupportedContent">
            <ul className="nav navbar-nav flex-grow-1 justify-content-center">
              <li className="nav-item px-3"><NavLink to="/home" activeClassName="active" className="nav-link navbar-nav-link">首页</NavLink></li>
              <li className="nav-item px-3"><NavLink to="/aboutus" activeClassName="active" className="nav-link navbar-nav-link">关于我们</NavLink></li>
              <li className="nav-item px-3"><NavLink to="/blog" activeClassName="active" className="nav-link navbar-nav-link">博客</NavLink></li>
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}
