import { Component } from "react";

class TextIcon extends Component {
    state = {
        icon: this.props.icon,
        text: this.props.text
    }

    render() {
        return (
            <div className="me-3">
                <img src={this.state.icon} width={25} height={25} alt='phone' /> {this.state.text}
            </div>
        )
    }
}

export default TextIcon