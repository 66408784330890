import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import Wrangler from '../imgs/car_img_main/4.jpg'
import Car from '../imgs/Car.png'
import Medal from '../imgs/Medal.png'
import Headset from '../imgs/Headset.png'
import CircleDollar from '../imgs/CurrencyCircleDollar.png'

import '../css/home.css'
import axios from 'axios'
import utils from './utils'

import BttButton from './bttButton'

class AboutUs extends Component {

    state = {
        banner_imgs: [],
    }

    componentWillMount = async () => {
        document.title = "95租车 - 洛杉矶最大的华人中高端租车平台";

        // load home page picture
        axios.get(utils.getUrl('exotics/vehicle-image/?filter={"tag": "Home Page"}'))
            .then(response => {
                console.log(response.data)
                this.setState({ banner_imgs: response.data })
            })
    }

    render() {

        return (
            <div style={{ overflowX: 'hidden' }} >
                <div className='container-fluid starter-container'>

                    <div id="carouselExampleControls" class="carousel slide carousel-fade" data-bs-ride="carousel">
                        <div class="carousel-inner d-flex align-items-center" style={{ maxHeight: 500, overflow: "hidden" }}>
                            <div className="carousel-item active">
                                <img src={Wrangler} class="starter-img" alt="home page main" />
                            </div>
                            {this.state.banner_imgs.map((img, index) => {
                                return (
                                    <div className="carousel-item">
                                        <img src={img.image} class="starter-img" alt="home page main" />
                                    </div>
                                )
                            })}
                            <div className='starter-text-btn-block'>
                                <div className='starter-text-main'>95 租车</div>
                                <div className='starter-text-sub'>专业做品质，真诚做服务</div>
                                <div className='starter-text-sub'>值得信赖的专业租车平台</div>
                                <a href="https://findself.va.mikecrm.com/w2L8vIO" target="_blank" rel="noreferrer"><button className='starter-btn btn'>开始租车</button></a>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="container my-3">
                    <div className='row'>
                        <div className='col-lg-8'>
                            <img src="https://via.placeholder.com/120x70" alt="Sample" style={{ width: '100%', borderRadius: 50 }} />
                        </div>
                        <div className='col-lg-4'>
                            <h1 className="header">关于我们</h1>
                            <p className="content">
                                欢迎来到我们的团队！我们是一支充满活力、充满创造力的团队，致力于共同实现卓越的目标。我们由一群热情、多才多艺的成员组成，每个人都在自己的领域中具有丰富的经验和专业知识。

                                我们团队的核心价值观是团结、合作和共赢。我们深信团队的力量，相互之间互补，携手前行。我们以开放、包容的态度对待每个成员，鼓励和支持彼此的创意和创新，相互学习和成长。

                                我们的团队拥有广泛的专业领域，包括技术、市场、设计、销售等多个领域的人才。我们充分发挥各自的优势，共同合作，形成了一个高效的团队合作模式，致力于为客户提供卓越的服务和产品。

                                我们注重团队成员的个人发展和职业成长，提供培训、学习和发展机会，鼓励团队成员积极参与项目，发挥创造力和创新精神，不断提升团队整体的综合实力。

                                我们团队有着愉快的工作氛围，注重团队文化的建设，秉持着相互尊重、信任、沟通和合作的原则，共同追求卓越。我们崇尚开放、包容、共享的团队精神，每个人都被视为团队的重要一员。

                                我们的团队充满了无限的潜力和机会，我们共同努力，携手前行，一起创造更加辉煌的明天！欢迎您加入我们的团队，一同开启激动人心的旅程
                            </p>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-center my-3'>
                        <div><h1>为什么选择我们</h1></div>
                        <div>为什么选择我们为什么选择我们为什么选择我们为什么选择我们为什么选择我们为什么选择我们为什么选择我们</div>
                    </div>
                    <div className="row justify-content-center my-5">
                        <div className="col-md-3 text-center">
                            <div className="d-flex flex-column align-items-center">
                                <div className="icon-bg" style={{ backgroundColor: 'orange', padding: '20px', borderRadius: '50%', width: '100px', height: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                    <img src={Headset} alt="Sample" style={{ height: 50 }} />
                                </div>
                                <h4 className="title">专业服务</h4>
                                <p className="subtitle">专业团队，24小时客服</p>
                            </div>
                        </div>
                        <div className="col-md-3 text-center">
                            <div className="d-flex flex-column align-items-center">
                                <div className="icon-bg" style={{ backgroundColor: 'orange', padding: '20px', borderRadius: '50%', width: '100px', height: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                    <img src={Medal} alt="Sample" style={{ height: 50 }} />
                                </div>
                                <h4 className="title">品质保障</h4>
                                <p className="subtitle">品质保障，专业送车</p>
                            </div>
                        </div>
                        <div className="col-md-3 text-center">
                            <div className="d-flex flex-column align-items-center">
                                <div className="icon-bg" style={{ backgroundColor: 'orange', padding: '20px', borderRadius: '50%', width: '100px', height: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                    <img src={Car} alt="Sample" style={{ height: 50 }} />
                                </div>
                                <h4 className="title">车辆齐全</h4>
                                <p className="subtitle">洛杉矶最大的华人中高端租车</p>
                            </div>
                        </div>
                        <div className="col-md-3 text-center">
                            <div className="d-flex flex-column align-items-center">
                                <div className="icon-bg" style={{ backgroundColor: 'orange', padding: '20px', borderRadius: '50%', width: '100px', height: '100px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
                                    <img src={CircleDollar} alt="Sample" style={{ height: 50 }} />
                                </div>
                                <h4 className="title">低额定金</h4>
                                <p className="subtitle">预约车辆，定金低至$200</p>
                            </div>
                        </div>
                    </div>






                </div>
                <BttButton></BttButton>
            </div>
        )
    }
}

export default withRouter(AboutUs);