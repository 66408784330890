import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'

import '../css/footer.css'
import PhoneCall from '../imgs/footer/PhoneCall.png'
import MapPinLine from '../imgs/footer/MapPinLine.png'
import ChatsCircle from '../imgs/footer/ChatsCircle.png'
import Book from '../imgs/footer/Book.png'
import InstagramLogo from '../imgs/footer/InstagramLogo.png'
import VideoCamera from '../imgs/footer/VideoCamera.png'

import TextIcon from './textIcon'

class Footer extends Component {
  state = {
    url: '/',
    volunteerIframe: false,
    therapistIframe: false,
    homepageIframe: false,
    width: 0,
  }

  componentDidMount = () => {
    this.setState({url: this.props.location.pathname});

    console.log(this.props.location.pathname);
  }

  componentDidUpdate = (prevProps) => {
    console.log(this.props.location.pathname);
    if (this.props.location.pathname !== prevProps.location.pathname){
      this.setState({url: this.props.location.pathname});
    }
  }

  subFooterText = () => {
    switch (this.state.url) {
      case "/counselorsunder30/":
        return (
          <div className="container-fluid grey-box px-lg-10 py-4">
            <div className="row justify-content-center align-items-center">
              {/* <div className="col-lg-6 col-12">
                  <p className="text-lg-end text-center pt-3">"您也是一名青年咨询师吗？诚邀您加入「FindSelf找我」"</p>
              </div> */}
              <div className="col-lg-3 col-12 text-center">
                <button className='btn-secondary p-3' onClick={()=>this.setState({therapistIframe: true})}>预约青年咨询师</button>
              </div>
            </div>
          </div>
        )
      case "/talk/":
        return (
          <div className="container-fluid grey-box px-lg-10 py-4">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-12">
                <p className="text-lg-end text-center pt-3">您也想成为一名「找我说」小伙伴，帮助他人吗？</p>
              </div>
              <div className="col-lg-3 col-12 text-lg-start text-center">
                <button className='btn-secondary p-3' onClick={()=>this.setState({volunteerIframe: true})}>小伙伴申请通道</button>
              </div>
            </div>
          </div>
        )
      case "/talking":
      case "/talktimetable":
      case "/events/":
      case "/crisisintervention":
        return (
          <div></div>
        )
      default:
        return (
          <div className="container-fluid grey-box px-lg-10 py-4">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-6 col-12">
                <p className="text-lg-end text-center pt-3">您也是一名心理健康工作者？诚邀您加入「FindSelf找我」</p>
              </div>
              <div className="col-lg-3 col-12 text-lg-start text-center">
                <button className='btn-secondary p-3' onClick={()=>this.setState({homepageIframe: true})}>即刻申请入驻</button>
                {/* <NavLink className="btn btn-secondary p-3" to="/signup/therapist">即刻申请入驻</NavLink> */}
              </div>
            </div>
          </div>
        )
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid px-lg-10 footer py-4 text-lg-start text-center pe-4">
          <div className='row gx-5 py-2'>
            <div className='col-md-5 d-flex justify-content-start'>
              <TextIcon icon={PhoneCall} text={'(626)888-8100'} />
              <TextIcon icon={MapPinLine} text={'9531 Valley Blvd., EI Monte, CA 91731'} />
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-4 d-flex justify-content-between'>
              <TextIcon icon={ChatsCircle} text={'wechat'} />
              <TextIcon icon={Book} text={'red'} />
              <TextIcon icon={InstagramLogo} text={'instagram'} />
              <TextIcon icon={VideoCamera} text={'youtube'} />
            </div>
            <div className='col-md-2'>
              扫码咨询租车客服
            </div>
          </div>
          <small>©95CarRental.com 2023. All rights reserved.</small>
        </div>
      </div>
    )
  }
}

export default withRouter(Footer)
