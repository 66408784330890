import axios from "axios";
import React, { Component } from "react";
import { Link, Redirect, withRouter } from 'react-router-dom'
import '../css/carDetail.css'

import MapPin from '../imgs/car_icons/MapPin@2x.png'
import Medal from '../imgs/car_icons/Medal@2x.png'
import utils from './utils'

import CarPreviewCard from "./carPreviewCard";

class CarDetail extends Component {

    state = {
        login_token: localStorage.getItem('loginToken'),
        window_height: window.innerHeight,
        window_width: window.innerWidth,
        window_width_breaker: 1080
    }

    updateWindowDimensions = () => {
        this.setState({window_width: window.innerWidth, window_height: window.innerHeight})
    }

    componentDidMount = async () => {
        document.title = "95租车 - 洛杉矶最大的华人中高端租车平台";
        window.scrollTo(0, 0);

        this.loadCarInfo();

        // register listener for window resize event
        window.addEventListener('resize', this.updateWindowDimensions)

    }

    componentDidUpdate = async (prevProps, prevState) => {
        if (prevProps.location.search !== this.props.location.search) {
            this.loadCarInfo();
            window.scroll(0, 0)
        }
    }

    loadCarInfo = async () => {
        // load vehicle data
        await axios.get(utils.getUrl(`exotics/vehicle/?filter={"id":${new URLSearchParams(this.props.location.search).get('id')}}`))
            .then(response => {
                this.setState({ vehicle: response.data.results[0] })
                // carousel pictures ressemble
                var imgSet = []
                for (let i = 0; i < response.data.results[0].image.length - 1; i++) {
                    imgSet.push({
                        img_left: response.data.results[0].image[i],
                        img_right: response.data.results[0].image[i + 1]
                    })
                }
                this.setState({ vehicleImgSet: imgSet })
            })

        // load recommeded vehicle data
        await axios.get(utils.getUrl(`exotics/vehicle/?page=1&page_size=2&filter={"make":"${this.state.vehicle.make}"}`))
            .then(response => {
                console.log(response.data.results)
                this.setState({ recommendedVehicles: response.data.results })
            })

        await axios.get(utils.getUrl(`exotics/vehicle/?page=1&page_size=4&target={"price":${this.state.vehicle.price}}`))
            .then(response => {
                console.log(response.data.results)
                this.setState({ recommendedVehicles: [...this.state.recommendedVehicles, ...response.data.results] })
            })
    }

    directOrderPage() {
        this.props.history.push('/order')
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    render() {
        const recommendedVehiclesSet = [];
        const vehicleIdSet = new Set();
        if (this.state.recommendedVehicles) {
            this.state.recommendedVehicles.forEach(vehicle => {
                if (!vehicleIdSet.has(vehicle.id)) {
                    vehicleIdSet.add(vehicle.id)
                    recommendedVehiclesSet.push(vehicle)
                }
            });
        }
        return (
            <div style={{overflowX: 'hidden'}} >
                {this.state.vehicle?
                    <React.Fragment>
                        <div className="container-fluid px-0">
                            <div className="row justify-content-center">
                                <div id="carouselExampleControls" class="carousel slide carousel-fade" data-bs-ride="carousel">
                                    <div class="carousel-inner d-flex align-items-center" style={{ maxHeight: 500, overflow: "hidden" }}>
                                        {this.state.window_width >= this.state.window_width_breaker ?
                                            this.state.vehicleImgSet ? this.state.vehicleImgSet.map((imgSet, index) => {
                                                return (
                                                    <div className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                                        <div className="d-flex">
                                                            <img src={imgSet.img_left.image} class="d-block w-50" alt="..." />
                                                            <img src={imgSet.img_right.image} class="d-block w-50" alt="..." />
                                                        </div>
                                                    </div>
                                                )
                                            }) : '' :
                                            this.state.vehicle.image.map((img, index) => {
                                                return (
                                                    <div className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                                        <div className="d-flex">
                                                            <img src={img.image} class="d-block w-100" alt="..." />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                    </div>
                                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="container main-content px-lg-10 pt-0 pb-5">
                            <div className="row g-1 g-lg-4 pt-5">
                                <div className="col-12 col-lg-8">    
                                    <div className="d-flex flex-row justify-content-between align-items-center mt-1" style={{ fontSize: 14, fontWeight: 300 }}>
                                        <h2> <strong>{this.state.vehicle.make} {this.state.vehicle.model} {this.state.vehicle.year}</strong> </h2>
                                        {/* <div className="d-flex" style={{ fontSize: 16, fontWeight: 300 }}>
                                            <div className="me-3">
                                                <img src={MapPin} className='location-rate-icons' alt="MapPin" />
                                                {this.state.vehicle.location}
                                            </div>
                                            <div>
                                                <img src={Medal} className='location-rate-icons' alt="Medal" />
                                                4.9
                                            </div>
                                        </div> */}
                                        <div className='d-flex flex-column me-3'>
                                            <div style={{ fontSize: 16, fontWeight: 600 }}>${this.state.vehicle.price}/天</div>
                                            <div style={{ fontSize: 14, fontWeight: 300, textDecoration: 'line-through', color: 'red' }}>原价${Math.round(this.state.vehicle.price * 1.2)}/天</div>
                                        </div>
                                    </div>
                                    <hr style={{ borderTop: '1px solid #bbb', marginTop: 8 }}></hr>
                                </div>
                                <div className="col-12 col-lg-4 text-center pb-3">
                                    <a href="https://findself.va.mikecrm.com/w2L8vIO" target="_blank" rel="noreferrer"><button class="btn btn-booking" style={{ width: '100%' }}>立即预约</button></a>
                                    {this.state.login_token !== null?
                                    <Link to={{
                                        pathname: "/order",
                                        state: this.state.vehicle
                                    }}>
                                        <button class="btn-contact" style={{ width: '100%' }}>创建订单</button>
                                    </Link> : ""}
                                    {/* <a href="tel:626-510-2575"><button class="btn-contact" style={{ width: '100%' }}>联系车主</button></a> */}
                                </div>
                            </div>
                            {
                                this.state.vehicle.description && this.state.vehicle.description.length > 0 ? (
                                    <div className="row g-1 g-lg-4 pt-5">
                                        <div className="col-12 col-lg-8">
                                            <h4> 基本信息 </h4>
                                            <br />
                                            <div>
                                                {this.state.vehicle.description.split("。").map(function (line) {
                                                    return <p>{line}</p>
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 text-center pb-3"></div>
                                    </div>
                                ) : ''
                            }
                            {
                                this.state.vehicle.config && this.state.vehicle.config.length > 0 ? (
                                    <div className="row g-1 g-lg-4 pt-5">
                                        <div className="col-12 col-lg-8">
                                            <h4> 配置 </h4>
                                            <br />
                                            <div>
                                                {this.state.vehicle.config.map(feature => {
                                                    return (
                                                        <div className="pill"> {feature.name} </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4 text-center pb-3"></div>
                                    </div>
                                ) : ''
                            }
                        </div>
                    </React.Fragment>:''  
                }
                {this.state.recommendedVehicles?
                    <div className="container main-content px-lg-10 pt-0 pb-5">
                        <div className="row d-flex flex-wrap g-5 mb-5">
                            <h4> 相关推荐 </h4>
                            {recommendedVehiclesSet.slice(0, 4).map(vehicle => {
                                return ( <CarPreviewCard {...vehicle} /> )
                            })}
                        </div>
                    </div>:''
                }
            </div>
        )
    }
}

export default withRouter(CarDetail);